module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-8SWBYR1TKG"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dbarros.dev","short_name":"dbarros","start_url":"/","background_color":"#333","theme_color":"#000","display":"standalone","icon":"content/assets/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"analytics":true},"credentials":{"apiKey":"AIzaSyC8CApsoPXusXolmwdz00IJmkQsOPlJyWY","authDomain":"dbarros-ef1d7.firebaseapp.com","databaseURL":"https://dbarros-ef1d7.firebaseio.com","projectId":"dbarros-ef1d7","storageBucket":"dbarros-ef1d7.appspot.com","messagingSenderId":"308922984568","appId":"1:308922984568:web:85a34450f54072dd45658d","measurementId":"G-8SWBYR1TKG"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
